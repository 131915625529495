import Avaliacao from "@/interfaces/Avaliacao";
import Disciplina from "@/interfaces/Disciplina";

const AvaliacaoColumn = [
  {
    name: "actions",
    align: "center",
    label: "Ações",
    field: "",
    sortable: false,
  },
  {
    name: "nome",
    align: "left",
    label: "Nome",
    field: "nome",
    sortable: true,
  },
  {
    name: "assunto",
    align: "left",
    label: "Assunto",
    field: "assunto",
    sortable: true,
  },
  {
    name: "disciplina",
    align: "left",
    label: "Disciplina",
    field: (row: Avaliacao): string | undefined => row?.disciplina?.descricao,
    sortable: true,
  },
  {
    name: "tipoAvaliacao",
    align: "left",
    label: "Tipo da Avaliação",
    field: (row: Avaliacao): string | undefined => row.tipoAvaliacao?.descricao,
    sortable: true,
  },
  {
    name: "data_inicio",
    align: "center",
    label: "Data Início",
    field: (row: Avaliacao): string =>
      row.data_inicio.toString().substring(0, 10),
    sortable: true,
  },
  {
    name: "data_fim",
    align: "center",
    label: "Data Fim",
    field: (row: Avaliacao): string => row.data_fim.toString().substring(0, 10),
    sortable: true,
  },
];

export default AvaliacaoColumn;
