
import { ref, defineComponent, onMounted } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { requiredField } from "@/util/form-rules";
import FormLoading from "../../layout/FormLoading.vue";
import Service from "@/services/admin/AvaliacaoService";
import CursoService from "@/services/admin/CursoService";
import TurmaService from "@/services/admin/TurmaService";
import DisciplinaService from "@/services/admin/DisciplinaService";
import TipoAvaliacaoService from "@/services/admin/TipoAvaliacaoService";
import TipoAvaliacao from "@/interfaces/TipoAvaliacao";
import Disciplina from "@/interfaces/Disciplina";
import Avaliacao from "@/interfaces/Avaliacao";
import Curso from "@/interfaces/Curso";
import Turma from "@/interfaces/Turma";
import Divisao from "@/interfaces/Divisao";
import BaseService from "@/services/admin/BaseService";
import DivisaoService from "@/services/auth/DivisaoService";
import store from "@/store";

interface Form {
  nome: string;
  url: string;
  url2: string;
  url3: string;
  nome_url: string;
  nome_url2: string;
  nome_url3: string;
  disciplina_id: string;
  disciplinas: string[];
  tipo_avaliacao_id: string;
  data_inicio: string;
  data_fim: string;
  divisao_id: string;
  curso_id: string;
  turma_id: string;
  avaliacao_id: string;
  descricao: string;
  imagem: string;
  correcao: string[];
  nota_minima: number;
  nota_maxima: number;
  assunto: string;
}

const baseForm: Form = {
  nome: "",
  descricao: "",
  imagem: "",
  url: "",
  url2: "",
  url3: "",
  nome_url: "",
  nome_url2: "",
  nome_url3: "",
  disciplina_id: "",
  disciplinas: [],
  tipo_avaliacao_id: "",
  data_inicio: "",
  data_fim: "",
  divisao_id: "",
  curso_id: "",
  turma_id: "",
  avaliacao_id: "",
  correcao: [""],
  assunto: "",
  nota_minima: 0,
  nota_maxima: 0,
};

export default defineComponent({
  components: { FormLoading },
  props: {
    avaliacoes: { default: [] },
  },
  setup(props, { emit }) {
    const $q = useQuasar();
    const show = ref(false);
    const form = ref<Form>();
    const row = ref<Avaliacao>();
    const disciplinas = ref<Disciplina[]>([]);
    const cursos = ref<Curso[]>([]);
    const turmas = ref<Turma[]>([]);
    const tiposAvaliacao = ref<TipoAvaliacao[]>([]);
    const divisoes = ref<Divisao[]>([]);
    const loading = ref<boolean>(false);
    const hiddenDivisao = ref<boolean>(false);

    const open = (data: Avaliacao | null): void => {
      if (data) {
        row.value = JSON.parse(JSON.stringify(data));
        if (row.value?.id) {
          const data_inicio: string = row.value.data_inicio.toString();
          const data_fim: string = row.value.data_fim.toString();
          form.value = {
            nome: row.value.nome,
            descricao: row.value?.descricao,
            imagem: "",
            url: row.value.url,
            url2: row.value.url2,
            url3: row.value.url3,
            nome_url: row.value.nome_url,
            nome_url2: row.value.nome_url2,
            nome_url3: row.value.nome_url3,
            disciplina_id: row.value.disciplina?.id,
            disciplinas: row.value.disciplinas ? row.value.disciplinas.map((disciplina) => disciplina.id) : [],
            tipo_avaliacao_id: row.value.tipoAvaliacao?.id ?? "",
            data_inicio: `${data_inicio.substr(6, 4)}-${data_inicio.substr(3, 2)}-${data_inicio.substr(0, 2)}`,
            data_fim: `${data_fim.substr(6, 4)}-${data_fim.substr(3, 2)}-${data_fim.substr(0, 2)}`,
            divisao_id: row.value.divisao?.id ?? "",
            curso_id: row.value.curso?.id ?? "",
            turma_id: row.value.turma?.id ?? "",
            avaliacao_id: row.value.avaliacao?.id ?? "",
            assunto: row.value?.assunto ?? "",
            nota_minima: row.value?.nota_minima ?? "",
            nota_maxima: row.value?.nota_maxima ?? "",
            correcao: row.value.correcao,
          };
          if (store.getters.divisaoId) {
            hiddenDivisao.value = true;
            form.value.divisao_id = store.getters.divisaoId;
          }
        } else {
          form.value = JSON.parse(JSON.stringify(baseForm));
          if (store.getters.divisaoId) {
            hiddenDivisao.value = true;
            if (form.value) {
              form.value.divisao_id = store.getters.divisaoId;
            }
          }
        }
      }
      show.value = true;
    };

    const addResolucao = (): void => {
      form?.value?.correcao.push("");
    };
    const buildFormData = (
      formData: any,
      data: any,
      parentKey: any = null
    ): FormData => {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
      return formData;
    };

    const submit = (): void => {
      if (!row.value?.id) {
        loading.value = true;
        let formData = new FormData();
        formData = buildFormData(formData, form.value);
        if (form.value?.imagem) {
          formData.append("imagem", form.value.imagem);
        }
        Service.create(formData)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Avaliação salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao salvar avaliação",
            });
          })
          .finally(() => (loading.value = false));
      } else {
        loading.value = true;
        let formData = new FormData();
        formData = buildFormData(formData, form.value);
        if (form.value?.imagem) {
          formData.append("imagem", form.value.imagem);
        }
        Service.update(row.value.id, formData)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Avaliação salva com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao salvar avaliação",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const destroy = (): void => {
      if (row.value) {
        loading.value = true;
        Service.delete(row.value.id)
          .then(() => {
            afterSubmit();
            showNotify({
              type: "positive",
              message: "Avaliação excluída com sucesso",
            });
          })
          .catch((err) => {
            console.log(err);
            showNotify({
              type: "negative",
              message: "Erro ao excluir avaliação",
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getDisciplinas = (): void => {
      loading.value = true;
      if (store.getters.divisaoId) {
        DivisaoService.getDisciplinasSelect(store.getters.divisaoId)
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      } else {
        BaseService.list("disciplinas")
          .then((res) => {
            disciplinas.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
          .finally(() => (loading.value = false));
      }
    };

    const getCursos = (): void => {
      if (store.getters.divisaoId) {
        DivisaoService.getCursos(store.getters.divisaoId)
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          });
      } else {
        CursoService.list()
          .then((res) => {
            cursos.value = res.data;
          })
          .catch((err) => {
            console.error(err.response.data.message);
            showNotify({
              type: "negative",
              message: err.response.data.message,
            });
          })
      }
    };

    const getTurmas = (): void => {
      loading.value = true;
      TurmaService.list()
        .then((res) => {
          turmas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar turmas",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getTiposAvaliacao = (): void => {
      loading.value = true;
      TipoAvaliacaoService.list()
        .then((res) => {
          tiposAvaliacao.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar tipos",
          });
        })
        .finally(() => (loading.value = false));
    };

    const getDivisoes = (): void => {
      loading.value = true;
      BaseService.list("divisoes")
        .then((res) => {
          divisoes.value = res.data;
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const afterSubmit = (): void => {
      emit("update");
      show.value = false;
      form.value = baseForm;
    };

    const getInput = (): string | undefined => {
      return tiposAvaliacao.value.find(
        (ta) => ta.id == form.value?.tipo_avaliacao_id
      )?.nome;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    onMounted(() => {
      getDisciplinas();
      getTiposAvaliacao();
      getCursos();
      getTurmas();
      getDivisoes();
    });

    return {
      show,
      open,
      form,
      row,
      requiredField,
      disciplinas,
      tiposAvaliacao,
      loading,
      showNotify,
      getCursos,
      getTurmas,
      getDisciplinas,
      getTiposAvaliacao,
      submit,
      afterSubmit,
      destroy,
      getInput,
      cursos,
      turmas,
      divisoes,
      getDivisoes,
      addResolucao,
      hiddenDivisao,
    };
  },
});
