import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = {
  key: 2,
  class: "col-7"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_editor = _resolveComponent("q-editor")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_FormLoading = _resolveComponent("FormLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"width":"90vw","max-width":"900px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.row.id ? "Editar Avaliação" : "Nova Avaliação"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_form, { onSubmit: _ctx.submit }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, { class: "row" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-12 q-mb-sm",
                    label: "Nome *",
                    modelValue: _ctx.form.nome,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.nome) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-12 q-mb-sm",
                    label: "Assunto *",
                    modelValue: _ctx.form.assunto,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.assunto) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_editor, {
                    modelValue: _ctx.form.descricao,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.descricao) = $event)),
                    class: "col-12 q-my-md q-mx-xs",
                    "min-height": "10rem",
                    toolbar: [
            [
              'bold',
              'italic',
              'strike',
              'underline',
              'subscript',
              'superscript',
            ],
            ['token', 'hr', 'link', 'custom_btn'],
            ['quote', 'unordered', 'ordered'],
            ['left', 'center', 'right', 'justify'],
          ]
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_file, {
                    dense: "",
                    outlined: "",
                    class: "col-12 q-mb-sm",
                    label: "Anexar Arquivo",
                    "aria-label": "Anexar Arquivo",
                    modelValue: _ctx.form.imagem,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.imagem) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "attach_file",
                        onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    "emit-value": "",
                    "map-options": "",
                    "stack-label": "",
                    label: "Tipo *",
                    class: "col-4 q-mb-sm q-pr-sm",
                    "option-value": "id",
                    "option-label": "descricao",
                    modelValue: _ctx.form.tipo_avaliacao_id,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.tipo_avaliacao_id) = $event)),
                    options: _ctx.tiposAvaliacao,
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "options", "rules"]),
                  (_ctx.getInput() == 'verificacao' || _ctx.getInput() == 'lista_de_revisao' || _ctx.getInput() == 'superacao' || _ctx.getInput() == 'simulado')
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 0,
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        "emit-value": "",
                        "map-options": "",
                        "stack-label": "",
                        label: "Disciplina *",
                        class: _normalizeClass(['col-8', 'q-mb-sm']),
                        "option-value": "id",
                        "option-label": "descricao",
                        modelValue: _ctx.form.disciplina_id,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.disciplina_id) = $event)),
                        options: _ctx.disciplinas,
                        rules: [_ctx.requiredField]
                      }, null, 8, ["modelValue", "options", "rules"]))
                    : (_ctx.getInput() == 'pelotao')
                      ? (_openBlock(), _createBlock(_component_q_select, {
                          key: 1,
                          dense: "",
                          outlined: "",
                          "lazy-rules": "",
                          "emit-value": "",
                          "map-options": "",
                          "stack-label": "",
                          label: "Disciplina *",
                          class: "col-8 q-mb-sm",
                          "option-value": "id",
                          "option-label": "descricao",
                          modelValue: _ctx.form.disciplina_id,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.disciplina_id) = $event)),
                          options: _ctx.disciplinas,
                          rules: [_ctx.requiredField]
                        }, null, 8, ["modelValue", "options", "rules"]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_2)),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    class: "col-6 q-mb-sm",
                    type: "number",
                    modelValue: _ctx.form.nota_minima,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.nota_minima) = $event)),
                    label: "Nota Mínima (Para ganhar medalha)"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    class: "col-6 q-mb-sm",
                    type: "number",
                    modelValue: _ctx.form.nota_maxima,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.nota_maxima) = $event)),
                    label: "Nota Máxima"
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-4 q-mb-sm",
                    label: "Nome URL *",
                    modelValue: _ctx.form.nome_url,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.form.nome_url) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-8 q-mb-sm",
                    label: "URL *",
                    modelValue: _ctx.form.url,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.url) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-4 q-mb-sm",
                    label: "Nome URL 2",
                    modelValue: _ctx.form.nome_url2,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.form.nome_url2) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-8 q-mb-sm",
                    label: "URL 2",
                    modelValue: _ctx.form.url2,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.form.url2) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-4 q-mb-sm",
                    label: "Nome URL 3",
                    modelValue: _ctx.form.nome_url3,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.nome_url3) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    class: "col-8 q-mb-sm",
                    label: "URL 3",
                    modelValue: _ctx.form.url3,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.form.url3) = $event))
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    type: "date",
                    class: "col-6 q-pr-sm",
                    label: "Data Início *",
                    modelValue: _ctx.form.data_inicio,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.form.data_inicio) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_q_input, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    type: "date",
                    class: "col-6 q-mb-sm",
                    label: "Data Fim *",
                    modelValue: _ctx.form.data_fim,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.form.data_fim) = $event)),
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "rules"]),
                  (!_ctx.hiddenDivisao)
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 3,
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        "emit-value": "",
                        "map-options": "",
                        "stack-label": "",
                        label: "Divisão *",
                        class: "col-12",
                        "option-value": "id",
                        "option-label": "descricao",
                        modelValue: _ctx.form.divisao_id,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.form.divisao_id) = $event)),
                        options: _ctx.divisoes,
                        rules: [_ctx.requiredField]
                      }, null, 8, ["modelValue", "options", "rules"]))
                    : _createCommentVNode("", true),
                  (_ctx.getInput() == 'simulado')
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 4,
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        "emit-value": "",
                        "map-options": "",
                        "stack-label": "",
                        label: "Parametros *",
                        class: _normalizeClass(['col-12', 'q-mb-sm']),
                        "option-value": "id",
                        "option-label": "descricao",
                        modelValue: _ctx.form.disciplinas,
                        "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.form.disciplinas) = $event)),
                        options: _ctx.disciplinas,
                        rules: [_ctx.requiredField],
                        multiple: ""
                      }, null, 8, ["modelValue", "options", "rules"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    color: "green",
                    icon: "add",
                    label: "Adicionar Resolução",
                    onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.addResolucao()))
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form?.correcao, (c, index) => {
                    return (_openBlock(), _createElementBlock("span", { key: index }, [
                      _createVNode(_component_q_input, {
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        class: "col-12 q-mb-sm",
                        label: 'Resolução nº' + (index + 1),
                        modelValue: _ctx.form.correcao[index],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.form.correcao[index]) = $event)
                      }, null, 8, ["label", "modelValue", "onUpdate:modelValue"])
                    ]))
                  }), 128))
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    type: "submit",
                    color: "primary",
                    class: "q-ma-sm",
                    icon: "save",
                    label: "Salvar"
                  }),
                  (_ctx.row.id)
                    ? (_openBlock(), _createBlock(_component_q_btn, {
                        key: 0,
                        color: "red",
                        class: "q-ma-sm",
                        icon: "block",
                        label: "Excluir",
                        onClick: _ctx.destroy
                      }, null, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _withDirectives(_createVNode(_component_q_btn, {
                    class: "q-ma-sm",
                    icon: "close",
                    label: "Cancelar"
                  }, null, 512), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSubmit"]),
          _createVNode(_component_FormLoading, { loading: _ctx.loading }, null, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}