
import { defineComponent, ref } from "vue";
import AvaliacaoColumn from "@/util/table-columns/AvaliacaoColumn";
import AvaliacaoForm from "@/components/admin/avaliacoes/AvaliacaoForm.vue";
import useList from "@/modules/useList";
import store from "@/store";
import TipoAvaliacao from "@/interfaces/TipoAvaliacao";
import Avaliacao from "@/interfaces/Avaliacao";
import Disciplina from "@/interfaces/Disciplina";
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  components: { AvaliacaoForm },
  setup() {
    const filtro = ref<string>("");
    const filtroTipo = ref<string>("");
    const filtroDisciplina = ref<string>("");
    const tipoAvaliacoes = ref<TipoAvaliacao[]>([]);
    const disciplinas = ref<Disciplina[]>([]);
    const avaliacoes = ref<Avaliacao[]>([]);
    const avaliacao = ref<Avaliacao>();
    const avaliacaoShow = ref<boolean>(false);
    const $q = useQuasar();

    const {
      rows,
      loading,
      filter,
      columns,
      list,
      formatData,
      modalForm,
      openModalForm,
    } = useList(
      store.getters.divisaoId
        ? "divisoes/avaliacoes/" + store.getters.divisaoId
        : "avaliacoes",
      AvaliacaoColumn
    );

    const copyLink = (values: any): void => {
      copyToClipboard(window.location.host + "/minhas-avaliacoes/" + values.id)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Link copiado com sucesso",
          });
        })
        .catch(() => {
          showNotify({
            type: "positive",
            message: "Falha ao copiar link da redação",
          });
        });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    const verResolucao = (a: any): void => {
      avaliacaoShow.value = true;
      avaliacao.value = a;
    };

    return {
      rows,
      loading,
      filter,
      columns,
      list,
      modalForm,
      openModalForm,
      formatData,
      filtro,
      filtroTipo,
      filtroDisciplina,
      disciplinas,
      tipoAvaliacoes,
      avaliacoes,
      store,
      avaliacao,
      avaliacaoShow,
      verResolucao,
      copyLink,
    };
  },
});
